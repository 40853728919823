.onboarding-container {
  margin: 20px;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.progress-nav .step-list {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin-bottom: 30px;
}

.step-list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    position: relative;
  }
  
  .step-list::before {
    content: '';
    position: absolute;
    top: calc(50% + 20px);
    left: 0;
    height: 4px;
    background-image: linear-gradient(to right, #667eea, #764ba2, #6B8DD6, #8E37D7);
    z-index: 1;
    width: 0; /* Initially no width */
    transition: width 0.3s ease;
  }
  
  .step-list li {
    flex: 1;
    text-align: center;
    position: relative;
    z-index: 2;
  }
  
  .step-list li::after {
    content: '';
    position: absolute;
    top: calc(50% + 20px);
    right: 0;
    height: 4px;
    background: #ddd; /* Background color */
    z-index: 2;
  }
  
  .completed-step::after,
  .current-step::after {
    width: 0; /* Initially hidden */
    transition: width 0.3s ease;
  }
  
  .step-list li:last-child::after {
    display: none; /* Hide for the last step */
  }
  

.form-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}

/* Success and error messages styling */
.message-success {
  color: #28a745;
  margin-top: 10px;
}

.message-error {
  color: #dc3545;
  margin-top: 10px;
}

/* Additional styles for interactive elements */
/* You can add more styles for specific elements as needed */

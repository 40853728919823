/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it appears above other elements */
  }
  
  .modal-content {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 100%;
    position: relative;
    text-align: center;
    overflow: hidden;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    color: #667eea; /* Close button color */
    cursor: pointer;
  }
  
  .close:hover {
    color: #333; /* Close button color on hover */
  }
  
  /* Blue Gradient Frame */
  .modal-content::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    background-image: linear-gradient(to right, #667eea, #764ba2, #6B8DD6, #8E37D7);
    border-radius: 15px;
    z-index: -1;
  }
  
  /* Modal Content Styles */
  .modal-header {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #333;
  }
  
  .modal-body {
    font-size: 16px;
    margin-bottom: 20px;
    color: #555;
  }
  
  /* Add this CSS to your helper text span */
  .text-indigo-600:hover:underline {
    cursor: pointer;
  }
  
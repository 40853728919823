.form-styling {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  .form-select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
    background-color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2212%22%20height%3D%228%22%20viewBox%3D%220%200%2012%208%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M6%208L11.1962%200.803848L0.803848%200.803848L6%208Z%22%20fill%3D%22%23464272%22/%3E%3C/svg%3E%0A');
    background-repeat: no-repeat;
    background-position: right 10px top 50%;
    cursor: pointer;
    transition: border-color 0.3s, box-shadow 0.3s;
  }
  
  .form-select:hover {
    border-color: #667eea; /* Hover border color */
  }
  
  .form-select:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(102, 126, 234, 0.5); /* Focus state */
  }
  
  .form-label {
    display: block;
    margin-bottom: 5px;
    color: #333;
    font-size: 16px;
  }
  
  .form-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
    justify-content: flex-start;
  }
  
  .submit-button {
    width: 100%;
    padding: 10px 20px;
    background-color: #4f46e5;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.5s, transform 0.5s;
    position: relative;
    overflow: hidden;
  }
  
  .submit-button {
    width: 100%;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    height: 55px;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    border-radius: 50px;
    transition: all .4s ease-in-out;
    background-image: linear-gradient(to right, #667eea, #764ba2, #6B8DD6, #8E37D7);
    box-shadow: 0 4px 15px 0 rgba(116, 79, 168, 0.75);
  }
  
  .submit-button:hover {
    background-position: 100% 0;
  }
  
  .submit-button:focus {
    outline: none;
  }

  .card-select {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .card-option {
    padding: 10px 15px;
    border-radius: 10px;
    background-color: #f0f0f0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .card-option:hover {
    background-color: #e0e0e0;
    transform: translateY(-2px);
    cursor: pointer;
  }
  
  .card-option.selected {
    background-color: #667eea;
    color: white;
    background-size: 300% 100%;
    transition: all .4s ease-in-out;
    background-image: linear-gradient(to right, #667eea, #764ba2, #6B8DD6, #8E37D7);
    box-shadow: 0 4px 15px 0 rgba(116, 79, 168, 0.75);
  }
  